import Vue from 'vue';
import Component from 'vue-class-component';

@Component()
export default class IsMobileMixin extends Vue {
  isMobile = false;
  matchMedia = null;

  created () {
    if (!this.matchMedia && window.matchMedia) {
      this.matchMedia = window.matchMedia('(min-width: 768px)');
      this.isMobile = !this.matchMedia.matches;
      this.matchMedia.addListener(this.setIsMobile);
    }
  }

  setIsMobile (e) {
    this.isMobile = !e.matches;
    if (this.onIsMobileChanged) this.onIsMobileChanged(this.isMobile);
  }

  destroyed () {
    if (this.matchMedia) this.matchMedia.removeListener(this.setIsMobile);
  }
}
